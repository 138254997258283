import * as React from "react";

function SvgComponent(props) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="75.000000pt" height="75.000000pt" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2228 4362 l-357 -317 0 -347 c-1 -192 -2 -348 -4 -348 -2 0 -97 41
-213 90 -115 50 -215 89 -222 88 -8 -2 -144 -118 -302 -258 l-289 -255 0 -914
-1 -915 35 34 35 34 0 861 0 861 33 32 c18 18 36 32 40 32 4 0 7 -381 7 -847
0 -845 0 -846 20 -828 20 18 20 31 20 870 l0 853 55 47 55 47 0 -641 c0 -634
0 -641 20 -641 20 0 20 7 20 659 l0 659 58 53 57 52 5 -904 c5 -896 5 -904 25
-904 20 0 20 8 23 930 l4 930 39 34 39 33 0 -966 0 -966 168 0 167 0 -3 90 -3
90 105 0 106 0 0 776 c0 704 1 776 16 770 9 -3 18 -6 20 -6 2 0 4 -346 4 -770
l0 -770 30 0 30 0 0 -84 c0 -58 -4 -86 -12 -89 -7 -3 8 -6 35 -6 l47 -1 0 865
c0 762 -2 865 -15 865 -13 0 -15 57 -14 453 l1 452 61 55 62 55 3 -773 2 -772
25 0 25 0 0 798 1 797 72 64 72 63 3 -1518 c2 -1395 3 -1519 19 -1536 9 -10
20 -18 25 -18 4 0 8 702 8 1560 l0 1560 41 38 c23 20 45 39 50 40 5 2 9 -702
9 -1647 l0 -1651 108 0 c107 0 109 0 137 -30 27 -28 33 -30 107 -30 l78 0 0
70 0 70 40 0 40 0 0 519 0 519 33 28 32 27 3 -547 c2 -538 2 -546 22 -546 20
0 20 7 20 569 l0 569 45 36 45 37 0 -550 c0 -544 0 -551 20 -551 20 0 20 7 20
568 l0 568 43 42 c23 23 46 42 50 42 4 0 7 -331 7 -735 0 -728 0 -735 20 -735
20 0 20 7 20 759 l0 759 33 28 32 27 3 -787 2 -786 53 0 c52 1 52 1 25 16 -22
12 -28 22 -28 49 0 19 2 35 4 35 2 0 57 -22 121 -50 64 -27 128 -50 143 -50
14 0 35 -9 47 -20 22 -20 218 -110 241 -110 11 0 14 19 14 78 l0 79 -282 120
-283 121 -3 37 c-4 45 -32 53 294 -86 141 -60 260 -109 266 -109 5 0 8 36 6
81 l-3 81 -282 120 -283 121 0 34 c0 18 4 33 9 33 4 0 130 -52 279 -115 149
-63 274 -115 277 -115 3 0 5 37 3 82 l-3 82 -275 118 c-151 65 -279 119 -283
121 -5 1 -7 19 -5 39 l3 36 279 -119 c154 -65 281 -119 283 -119 1 0 3 37 3
83 l0 83 -282 120 -283 119 -3 38 c-2 20 -1 37 2 37 2 0 125 -52 273 -115 147
-63 273 -115 280 -115 10 0 13 22 13 79 l0 79 -282 120 -283 120 -3 36 c-2 20
1 36 5 36 5 0 129 -52 276 -115 148 -63 273 -115 278 -115 6 0 9 33 7 81 l-3
81 -282 120 -283 121 0 34 c0 18 4 33 9 33 6 0 131 -52 280 -115 149 -63 273
-115 276 -115 9 0 5 69 -5 75 -6 3 -145 64 -309 134 l-300 128 -50 -44 -50
-44 -3 111 -3 112 -387 165 -388 165 0 34 c0 19 4 34 9 34 5 0 173 -70 373
-156 199 -86 371 -159 381 -161 14 -5 17 2 17 39 l0 45 -387 165 -388 165 -3
36 c-2 21 0 37 3 37 3 0 174 -72 380 -160 206 -88 379 -160 385 -160 5 0 10
19 10 43 l0 42 -388 165 -387 166 -3 38 c-3 29 0 37 10 33 7 -3 177 -75 378
-161 201 -86 371 -156 378 -156 9 0 12 12 10 42 l-3 42 -387 165 -388 166 0
32 c0 18 5 33 11 33 6 0 176 -70 378 -156 201 -86 372 -159 379 -162 10 -3 12
7 10 43 l-3 47 -387 165 -388 165 0 34 c0 19 4 34 9 34 5 0 173 -70 373 -156
199 -86 371 -159 381 -161 14 -5 17 2 17 39 l0 45 -387 165 -388 165 -3 36
c-2 21 0 37 3 37 3 0 174 -72 380 -160 206 -88 379 -160 385 -160 5 0 10 19
10 43 l0 42 -388 165 -387 166 -3 38 c-3 29 0 37 10 33 7 -3 177 -75 378 -161
201 -86 371 -156 378 -156 9 0 12 12 10 42 l-3 41 -380 163 c-209 90 -384 165
-388 166 -5 2 -7 20 -5 39 l3 36 384 -163 c211 -90 386 -164 388 -164 2 0 3
16 1 37 l-3 36 -428 184 c-236 100 -429 183 -430 182 -1 0 -163 -143 -359
-317z m-171 -1089 c-3 -2 -25 5 -51 16 l-46 20 0 350 0 349 48 42 47 42 3
-408 c1 -224 1 -409 -1 -411z m-467 -329 c0 -406 -2 -464 -15 -464 -13 0 -15
59 -15 470 0 413 2 469 15 464 13 -5 15 -67 15 -470z m159 395 l31 -11 0 -659
0 -659 -35 0 -35 0 0 670 c0 369 2 670 4 670 1 0 17 -5 35 -11z"/>
<path d="M1828 1433 l3 -198 -41 42 c-38 41 -40 45 -40 108 l0 65 -287 -1
-288 -1 -410 -422 c-225 -231 -410 -425 -412 -431 -2 -6 28 -9 81 -7 l84 3
333 341 333 340 96 -99 c52 -54 202 -207 332 -341 l237 -243 246 -2 c135 -1
245 0 245 3 0 3 -51 58 -113 123 l-113 119 -92 -4 c-51 -2 -91 0 -90 4 2 5 94
102 206 218 l204 210 69 0 c38 0 69 3 69 7 0 4 -39 47 -87 95 l-88 88 -157 0
-158 0 0 65 c0 37 4 65 10 65 6 0 10 11 10 25 0 25 -1 25 -92 25 l-93 0 3
-197z"/>
<path d="M3080 1261 c0 -11 5 -23 10 -26 6 -3 10 -26 10 -51 l0 -44 -90 0
c-49 0 -90 -3 -90 -8 0 -4 63 -70 140 -147 76 -76 137 -141 135 -143 -3 -3
-31 -5 -62 -6 l-58 -1 -3 -157 -3 -158 -427 0 -427 0 -100 -100 -100 -100 611
0 610 0 195 198 c107 108 250 253 317 322 l123 125 317 -323 317 -324 78 4 79
3 -397 408 -397 407 -269 0 -269 0 0 -68 c0 -64 -2 -70 -33 -100 -18 -18 -36
-32 -40 -32 -11 0 -8 288 3 295 6 3 10 15 10 26 0 17 -8 19 -95 19 -87 0 -95
-2 -95 -19z"/>
<path d="M2218 1046 c-82 -85 -148 -156 -148 -158 0 -1 106 -2 237 -1 l236 2
118 123 117 123 91 -90 91 -90 0 -177 0 -178 35 0 35 0 0 146 0 146 25 -4 c14
-3 25 -3 25 -1 0 3 -66 74 -147 159 l-147 154 -211 0 -210 -1 -147 -153z"/>
<path d="M4105 981 c27 -28 53 -51 57 -51 4 0 8 13 8 29 0 28 -9 34 -105 70
-5 2 12 -20 40 -48z"/>
<path d="M1070 940 l0 -50 50 0 50 0 0 50 0 50 -50 0 -50 0 0 -50z"/>
<path d="M1210 940 l0 -50 50 0 50 0 0 50 0 50 -50 0 -50 0 0 -50z"/>
<path d="M2724 946 c-9 -24 2 -45 23 -42 24 4 25 49 0 54 -9 2 -20 -4 -23 -12z"/>
<path d="M2790 932 c0 -24 4 -28 23 -27 16 1 22 8 22 26 0 17 -6 25 -22 27
-19 3 -23 -1 -23 -26z"/>
<path d="M2724 876 c-9 -24 2 -45 23 -42 24 4 25 49 0 54 -9 2 -20 -4 -23 -12z"/>
<path d="M2790 862 c0 -24 4 -28 23 -27 16 1 22 8 22 26 0 17 -6 25 -22 27
-19 3 -23 -1 -23 -26z"/>
<path d="M1070 800 l0 -50 50 0 50 0 0 50 0 50 -50 0 -50 0 0 -50z"/>
<path d="M1210 800 l0 -50 50 0 50 0 0 50 0 50 -50 0 -50 0 0 -50z"/>
<path d="M3770 633 l0 -48 42 1 43 1 3 47 3 46 -45 0 -46 0 0 -47z"/>
<path d="M3902 633 l3 -46 45 0 45 0 3 46 3 47 -51 0 -51 0 3 -47z"/>
<path d="M3770 503 l0 -48 42 1 43 1 3 47 3 46 -45 0 -46 0 0 -47z"/>
<path d="M3902 503 l3 -46 45 0 45 0 3 46 3 47 -51 0 -51 0 3 -47z"/>
</g>
</svg>
  );
}

export default SvgComponent;
