import * as React from "react";

function SvgComponent(props) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="200.000000pt" height="200.000000pt" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M2333 3909 l-213 -190 0 -214 c0 -118 -3 -215 -7 -215 -5 0 -67 25
-140 56 l-131 57 -181 -162 -181 -162 0 -561 c0 -560 0 -561 20 -543 20 18 20
31 20 550 l0 532 25 23 25 23 0 -522 c0 -414 3 -522 13 -519 9 4 13 121 15
535 l2 529 30 29 30 29 0 -392 c0 -342 2 -392 15 -392 13 0 15 51 15 403 l0
403 33 32 c18 18 36 32 40 32 4 0 7 -252 7 -560 0 -367 3 -560 10 -560 7 0 10
198 10 573 l0 574 25 23 25 23 2 -594 3 -594 100 -3 100 -3 -3 55 -4 55 69 3
68 3 3 478 c1 312 6 476 12 472 6 -4 10 -178 10 -479 l0 -474 21 -5 c19 -5 21
-11 17 -56 -5 -48 -4 -51 18 -51 l24 0 0 535 c0 350 -3 535 -10 535 -6 0 -10
100 -10 279 l0 279 40 32 40 32 0 -476 c0 -416 2 -476 15 -476 13 0 15 61 15
490 l0 491 43 39 42 39 3 -933 c2 -814 4 -935 17 -946 13 -11 15 97 15 947 l0
959 28 27 c15 15 31 27 35 27 4 0 7 -457 7 -1015 l0 -1015 63 0 c51 0 68 -4
82 -20 14 -15 31 -20 72 -20 l53 0 0 45 c0 38 3 45 20 45 20 0 20 7 20 318 0
296 1 320 18 335 10 10 22 17 25 17 4 0 7 -151 7 -335 0 -217 4 -335 10 -335
6 0 10 123 10 348 l0 349 23 21 c13 12 26 22 30 22 4 0 7 -151 7 -335 0 -217
4 -335 10 -335 6 0 10 123 10 348 l0 348 30 29 30 29 0 -457 c0 -400 2 -457
15 -457 13 0 15 59 15 468 0 456 1 469 20 487 20 18 20 17 20 -468 l0 -487 33
0 c26 1 29 2 15 11 -16 9 -26 49 -12 49 3 0 37 -13 76 -30 38 -17 79 -30 89
-30 10 0 19 -4 19 -9 0 -7 139 -71 155 -71 3 0 5 22 5 49 l0 49 -172 74 c-143
61 -174 78 -176 96 -2 12 -1 22 1 22 3 0 79 -31 171 -70 91 -38 168 -70 171
-70 3 0 5 22 5 49 l0 49 -172 74 c-143 61 -174 78 -176 96 -2 12 -1 22 2 22 2
0 78 -31 167 -70 90 -38 167 -70 171 -70 4 0 8 22 8 49 l0 49 -172 74 c-143
61 -174 78 -176 96 -2 12 -1 22 2 22 2 0 78 -31 167 -70 90 -38 167 -70 171
-70 4 0 8 22 8 49 l0 49 -175 73 c-153 65 -175 77 -175 97 0 12 2 22 4 22 3 0
78 -31 167 -70 90 -38 167 -70 171 -70 4 0 8 22 8 48 l0 49 -175 74 c-152 65
-175 77 -175 97 0 12 1 22 3 22 2 0 77 -31 168 -70 90 -38 167 -70 171 -70 5
0 8 22 8 48 l0 49 -175 74 c-156 66 -175 77 -175 98 0 15 5 21 13 18 6 -3 81
-35 165 -71 85 -36 158 -66 164 -66 5 0 8 10 6 22 -2 17 -37 36 -191 101
l-187 80 -23 -21 c-13 -12 -26 -22 -30 -22 -3 0 -7 28 -9 63 l-3 62 -235 101
c-198 85 -236 105 -238 124 -5 28 -16 31 243 -80 115 -50 215 -90 222 -90 17
0 17 34 1 48 -7 5 -115 53 -240 107 -203 86 -228 100 -228 120 0 22 12 18 223
-72 275 -118 259 -113 255 -78 -3 27 -16 34 -240 129 -216 91 -238 103 -238
124 0 15 5 22 13 19 6 -3 113 -48 235 -101 123 -53 226 -96 229 -96 2 0 3 12
1 28 -3 25 -18 34 -238 128 -198 85 -236 105 -238 124 -5 28 -16 31 243 -80
115 -50 215 -90 222 -90 17 0 17 34 1 48 -7 5 -115 53 -240 107 -203 86 -228
100 -228 120 0 22 12 18 223 -72 122 -52 230 -97 240 -100 17 -5 22 11 12 39
-3 8 -111 59 -240 113 -214 90 -235 101 -235 123 0 15 5 22 13 19 6 -3 113
-48 235 -101 123 -53 226 -96 229 -96 2 0 3 12 1 28 -3 25 -18 34 -238 128
-193 83 -236 105 -238 123 -2 11 -1 21 3 21 3 0 106 -43 229 -95 122 -52 228
-95 234 -95 16 0 15 35 0 48 -7 5 -115 53 -240 107 -208 89 -228 99 -228 121
0 20 3 23 18 16 62 -32 454 -193 458 -189 3 3 3 14 0 24 -4 12 -85 52 -259
126 -138 59 -259 107 -267 107 -8 -1 -111 -86 -227 -191z m-107 -666 c-2 -2
-15 2 -30 8 l-26 12 0 218 0 218 28 21 27 22 3 -248 c1 -136 1 -249 -2 -251z
m-286 -209 c0 -183 -4 -284 -10 -284 -6 0 -10 104 -10 291 0 191 3 288 10 284
6 -4 10 -112 10 -291z m120 -169 c0 -398 0 -405 -20 -405 -20 0 -20 7 -20 415
0 413 0 416 20 405 19 -10 20 -21 20 -415z"/>
<path d="M2089 2223 c0 -4 0 -60 1 -123 l2 -115 -26 24 c-20 19 -26 34 -26 68
l0 43 -174 0 -174 0 -254 -261 c-139 -143 -254 -264 -256 -270 -2 -5 18 -9 45
-9 l48 0 207 212 207 213 206 -212 207 -213 149 0 c82 0 149 3 149 8 0 4 -30
38 -68 75 l-68 67 -58 0 -58 0 127 135 127 135 46 0 46 0 -59 60 -59 60 -94 0
-94 0 4 53 3 52 -52 3 c-29 2 -53 -1 -54 -5z"/>
<path d="M2867 1965 l6 -45 -59 0 -59 0 90 -90 90 -90 -37 0 -37 0 -3 -97 -3
-98 -265 -3 -266 -2 -59 -60 -59 -60 375 0 375 0 194 195 c106 107 196 195
198 195 2 0 91 -88 196 -196 l191 -196 47 4 47 3 -240 248 -241 247 -164 0
-164 0 0 -38 c0 -29 -7 -45 -26 -63 l-26 -24 4 108 4 107 -58 0 -58 0 7 -45z"/>
<path d="M2326 1864 l-88 -94 144 0 144 0 74 75 74 75 53 -52 53 -52 0 -114
c0 -112 0 -113 23 -110 21 3 22 8 25 91 2 74 5 87 20 87 10 0 -20 36 -78 95
l-95 95 -130 -1 -130 0 -89 -95z"/>
<path d="M3490 1825 c27 -29 40 -32 40 -10 0 9 -12 20 -26 25 -38 15 -40 13
-14 -15z"/>
<path d="M1620 1800 c0 -27 3 -30 30 -30 27 0 30 3 30 30 0 27 -3 30 -30 30
-27 0 -30 -3 -30 -30z"/>
<path d="M1710 1800 c0 -27 3 -30 30 -30 27 0 30 3 30 30 0 27 -3 30 -30 30
-27 0 -30 -3 -30 -30z"/>
<path d="M2640 1795 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2680 1795 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2640 1755 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2680 1755 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M1620 1716 c0 -33 2 -36 31 -36 28 0 30 2 27 33 -2 27 -8 33 -30 35
-26 3 -28 1 -28 -32z"/>
<path d="M1710 1716 c0 -33 2 -36 31 -36 28 0 30 2 27 33 -2 27 -8 33 -30 35
-26 3 -28 1 -28 -32z"/>
<path d="M3282 1613 c2 -22 9 -29 31 -31 24 -3 27 0 27 27 0 28 -3 31 -31 31
-27 0 -30 -3 -27 -27z"/>
<path d="M3370 1610 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M3280 1530 c0 -27 3 -30 30 -30 27 0 30 3 30 30 0 27 -3 30 -30 30
-27 0 -30 -3 -30 -30z"/>
<path d="M3370 1530 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M415 1275 c-18 -17 -25 -35 -25 -63 0 -44 20 -67 88 -101 77 -39 43
-101 -39 -72 -48 16 -59 14 -59 -14 0 -32 26 -45 90 -45 79 0 119 29 120 85 0
42 -23 71 -81 101 -58 29 -71 51 -43 71 18 13 48 13 85 -2 10 -4 19 3 27 20
11 23 9 26 -14 35 -14 6 -48 10 -75 10 -40 0 -55 -5 -74 -25z"/>
<path d="M650 1136 c0 -140 -3 -167 -16 -181 -10 -9 -25 -14 -35 -12 -15 4
-19 -1 -19 -24 0 -27 3 -29 39 -29 28 0 49 7 68 24 l28 24 3 181 4 181 -36 0
-36 0 0 -164z"/>
<path d="M920 1140 l0 -160 30 0 30 0 0 70 0 70 65 0 65 0 0 -70 0 -70 35 0
35 0 0 160 0 160 -35 0 -35 0 0 -65 0 -66 -62 3 -63 3 -3 63 c-3 61 -3 62 -32
62 l-30 0 0 -160z"/>
<path d="M1297 1270 c-71 -62 -71 -198 -1 -259 31 -28 42 -31 99 -31 58 0 68
3 100 31 41 36 60 94 51 157 -13 92 -59 132 -155 132 -53 0 -65 -4 -94 -30z
m150 -44 c25 -21 28 -31 28 -86 0 -55 -3 -65 -28 -86 -52 -44 -111 -21 -126
49 -25 108 54 185 126 123z"/>
<path d="M1610 1140 l0 -160 30 0 29 0 3 121 3 122 36 -119 c34 -117 36 -119
66 -122 l31 -3 40 118 40 118 1 -117 1 -118 30 0 30 0 0 160 0 160 -45 0 -44
0 -40 -122 c-44 -139 -38 -141 -86 25 l-29 97 -48 0 -48 0 0 -160z"/>
<path d="M2040 1140 l0 -160 90 0 90 0 0 30 0 30 -60 0 -60 0 0 40 0 40 55 0
c52 0 55 1 55 24 0 23 -4 25 -52 28 -52 3 -53 4 -56 36 l-3 32 60 0 61 0 0 30
0 30 -90 0 -90 0 0 -160z"/>
<path d="M2400 1141 l0 -161 35 0 35 0 0 60 c0 53 2 60 19 60 13 0 32 -20 56
-60 33 -55 40 -60 71 -60 19 0 34 2 34 6 0 3 -18 32 -40 64 -22 32 -40 61 -40
64 0 3 11 16 25 30 35 35 35 94 0 126 -21 20 -38 24 -110 28 l-85 5 0 -162z
m148 65 c4 -33 -10 -46 -50 -46 -26 0 -28 3 -28 41 l0 40 38 -3 c33 -3 37 -6
40 -32z"/>
<path d="M2700 1140 l0 -160 90 0 90 0 0 30 c0 30 -1 30 -55 30 l-55 0 0 40 0
40 50 0 c47 0 50 2 50 25 0 23 -3 25 -50 25 l-50 0 0 35 0 35 55 0 c54 0 55 0
55 30 l0 30 -90 0 -90 0 0 -160z"/>
<path d="M2959 1271 c-51 -51 -31 -110 51 -155 57 -31 69 -48 49 -72 -13 -16
-49 -15 -106 2 -20 6 -23 3 -23 -24 0 -36 13 -42 89 -42 90 0 134 46 110 117
-8 26 -24 41 -66 65 -61 35 -73 52 -50 75 13 13 23 14 58 5 24 -6 44 -10 45
-9 1 1 5 14 9 28 8 31 -10 39 -88 39 -40 0 -53 -5 -78 -29z"/>
<path d="M3200 1140 l0 -160 90 0 90 0 0 30 0 30 -60 0 -60 0 0 40 0 40 55 0
c52 0 55 1 55 24 0 23 -4 25 -52 28 -52 3 -53 4 -56 36 l-3 32 60 0 61 0 0 30
0 30 -90 0 -90 0 0 -160z"/>
<path d="M3509 1273 c-25 -65 -99 -278 -99 -285 0 -4 16 -8 35 -8 32 0 35 3
45 40 l11 40 58 0 58 0 12 -40 c11 -37 15 -40 47 -40 41 0 44 -20 -26 180
l-47 135 -42 3 c-37 3 -42 0 -52 -25z m70 -85 c7 -24 15 -51 17 -60 5 -15 -1
-18 -35 -18 -23 0 -41 3 -41 7 0 16 34 113 40 113 4 0 12 -19 19 -42z"/>
<path d="M3760 1140 l0 -160 30 0 30 0 0 60 c0 53 2 60 20 60 14 0 32 -19 57
-60 36 -56 40 -60 76 -60 l37 0 -45 67 -45 67 24 26 c30 32 37 64 24 102 -14
40 -59 58 -143 58 l-65 0 0 -160z m140 85 c10 -12 10 -21 2 -40 -9 -20 -19
-25 -47 -25 -34 0 -35 1 -35 40 0 39 1 40 34 40 18 0 39 -7 46 -15z"/>
<path d="M4104 1281 c-41 -25 -64 -77 -64 -146 0 -103 47 -155 141 -155 75 0
89 6 89 40 0 27 -1 28 -32 19 -52 -14 -77 -11 -103 16 -21 20 -25 34 -25 83 0
74 21 105 71 106 19 0 43 -3 55 -7 16 -6 23 -2 32 18 11 23 9 26 -14 35 -42
16 -116 12 -150 -9z"/>
<path d="M4340 1140 l0 -160 30 0 30 0 0 70 0 70 65 0 65 0 0 -70 0 -70 35 0
35 0 0 160 0 160 -35 0 -35 0 0 -65 0 -66 -62 3 -63 3 -3 63 c-3 61 -3 62 -32
62 l-30 0 0 -160z"/>
</g>
</svg>

  );
}

export default SvgComponent;
