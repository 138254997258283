import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/new/4.jpg";
import ProjectImg2 from "../../assets/img/new/2.jpg";
import ProjectImg3 from "../../assets/img/new/3.jpg";
import ProjectImg4 from "../../assets/img/new/5.jpg";
import ProjectImg5 from "../../assets/img/new/6.jpg";
import ProjectImg6 from "../../assets/img/new/7.jpg";
import AddImage2 from "../../assets/img/new/1.jpg";

export default function Projects() {

  


  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Why should I work with SJ Home Research?</h1>
            <p className="font13">
              We are able to come up with solutions that work for you. We understand things happen & you need to sell!
              <br />
              We will buy as-is, close in 30 days or less, & get you a cash offer within 72 hours!
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Close in 30 days or less"
                text="We have access to cash that can get you paid for your property quickly. We know speed is convenience & you need it faster than using an agent."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="We always buy as-is, period."
                text="No need for fixing the floors, painting the walls, or staging the property like a realtor would need, we always buy as-is so it is hassle free for you."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="No excessive fees or commissions"
                text="If you sell your home with an agent, you always have to pay both sides of the realtor commissions. With us you will be able to put more cash in your pocket, everytime."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="High quality & fast offers"
                text="We try to be creative & get you the most for your property. Wether you have a mortgage, liens, or tax problems, we will always be able to provide a fast, high quality offer."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="No open houses or touring"
                text="We are able to buy your property without ever needing to hold an open house or tour the property. With just a 15-20 minute phone call, we have enough information to make an offer."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Only close with title attorneys"
                text="We only close deals with certified & licensed title attorneys in the State of Alabama. This assures that all parties are getting exactly what was agreed upon & they make sure you get paid."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <br/><br/><br/>
          </div>
          <br/><br/><br/>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about us</h4>
              <h2 className="font40 extraBold">Who is SJ Home Research?</h2>
              <p className="font12">
                We are real estate investors in the Greater Birmingham area that are always looking to buy deals. We have access to cash & investors with many different options to always provide you with the highest quality offers we can get.
                We are always striving to help people with any situation they may have & we look forward to being able to help you!
              </p>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <br/><br/>
    </Wrapper>
    
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
