import React, {useEffect, useState} from "react";
import ReactPixel from 'react-facebook-pixel';
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/new/8.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { useForm } from "react-hook-form";
import { createClient } from '@supabase/supabase-js'

export default function Header() {

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init('1991581281207641', options);

  const supabase = createClient('https://sghratplefxsgfrlydsh.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNnaHJhdHBsZWZ4c2dmcmx5ZHNoIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYyMjY4MjEsImV4cCI6MjAxMTgwMjgyMX0.Ff28Wql1ZqSvDFyNkx427W1LIANCX7MT9CppC6k6e9c')
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [hidden, setHidden] = useState(null);
  const onSubmit = async data => await supabase
  .from('leads')
  .insert(data)
  .then(() => {
    setHidden(true);
    ReactPixel.track('CompleteRegistration');
  })
  

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Sell your home as-is & get paid in 30 days or less.</h1>
          <HeaderP className="font13 semiBold">
          Whether you're dealing with pre-foreclosure, a fixer-upper, liens, or tax delinquencies, or simply just don't want to deal with agents, staging, open house, commissions, or inspections, SJ Home Research is your solution.
          </HeaderP>
          <form onSubmit={handleSubmit(onSubmit)}>
          {/* register your input into the hook by invoking the "register" function */}
          <StyledInput hidden={hidden} type='name' placeholder="Name" required {...register("name")} />
          <StyledInput hidden={hidden} type='email' placeholder="Email" required {...register("email")} />
          <StyledInput hidden={hidden} type='tel' placeholder="Phone Number" required {...register("phone")} />
          <StyledInput hidden={hidden} type='text' placeholder="Property Address" required {...register("addr")} />

          <div>
              <p hidden={!hidden} className="font15 blackColor">
                <em>We have successfully received your submission, we will be in touch within 24 hrs. <br></br>Your data is protected under our privacy policy, you can click <a href="https://sghratplefxsgfrlydsh.supabase.co/storage/v1/object/public/HOMES/SJ%20Home%20Research%20Privacy%20Policy.pdf?t=2023-10-03T03%3A16%3A42.354Z">here</a> to read it.
                </em>
              </p>
            </div>

          {/* errors will return when field validation fails  */}
          {errors.exampleRequired && <span>This field is required</span>}

            
          
          
          <StyledSubmitButton hidden={hidden} type="submit" />
        </form>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" width={426} height={607} style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>We will work with any situation you have to help you get the cash you need.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>SJ HOME RESEARCH</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}




const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const StyledSubmitButton = styled.input`
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: #526fd9; /* Change to your desired background color */
  color: white; /* Change to your desired text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;

  /* Add hover styles if desired */
  &:hover {
    background-color: your-hover-background-color; /* Change to your desired hover background color */
  }
`;

const StyledInput = styled.input`
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;

  &:-webkit-autofill {
    /* Override styles for autofilled inputs */
    color: #000000 !important;
    text-color: #000000 !important;
  }

`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


